export const AdditionalFormsURLEnum = {
    "shadowfax": process.env.REACT_APP_FORM_IO_SHADOWFAX_FORM_URL,
    //"dunzo": process.env.REACT_APP_FORM_IO_DUNZO_FORM_URL,
    "shadowfax_3pl": process.env.REACT_APP_FORM_IO_SHADOWFAX_FORM_URL,
    // "Dunzo_Bangalore_Mumbai": "https://sphnlcqnxxthwup.form.io/dunzoadditionalmumbaibangalore",
    "uber": process.env.REACT_APP_FORM_IO_UBER_FORM_URL,
    "uber auto": process.env.REACT_APP_FORM_IO_UBER_FORM_URL,
    "uber_3pl": process.env.REACT_APP_FORM_IO_UBER_3PL_FORM_URL,
    "zepto_3pl": process.env.REACT_APP_FORM_IO_ZEPTO_3PL_FORM_URL,
    "zepto": 'added_custom_form',
    "porter": process.env.REACT_APP_FORM_IO_PORTER_FORM_URL,
    "loadshare": process.env.REACT_APP_FORM_IO_LOADSHARE_FORM_URL,
    "rapido": process.env.REACT_APP_FORM_IO_RAPIDO_FORM_URL,
    "rapido_3pl": process.env.REACT_APP_FORM_IO_RAPIDO_3PL_FORM_URL,
    "zomato": process.env.REACT_APP_FORM_IO_ZOMATO_FORM_URL,
    "swiggy": 'added_custom_form',
    "rapido_3pl_staffing": process.env.REACT_APP_FORM_IO_RAPIDO_3PL_STAFFING_FORM_URL,
    "delhivery_3pl": process.env.REACT_APP_FORM_IO_DELHIVERY_3PL_FORM_URL,
    "bigbasket_3pl": process.env.REACT_APP_FORM_IO_BIGBASKET_3PL_FORM_URL,
    "blinkit_3pl": process.env.REACT_APP_FORM_IO_BLINKIT_3PL_FORM_URL,
    "zomato_3pl": process.env.REACT_APP_FORM_IO_ZOMATO_3PL_FORM_URL,
    "grab-flipkart": process.env.REACT_APP_FORM_IO_GRAB_FLIPKART_FORM_URL,
    "amazon flex": process.env.REACT_APP_FORM_IO_AMAZON_FLEX_FORM_URL,
    "delhivery_3pl_salary": process.env.REACT_APP_FORM_IO_DELHIVERY_3PL_SALARY_FORM_URL,
    "blinkit": 'added_custom_form',
    "swiggy instamart": 'added_custom_form',
}
