import React, { Fragment, useEffect, useRef, useState } from 'react'
import Navbar from './Navbar'
import {CopyOutlined, UserOutlined,ArrowLeftOutlined, PlusOutlined, AppstoreAddOutlined, PhoneOutlined, CloudDownloadOutlined,SlidersOutlined, UpOutlined, DownOutlined, CloseOutlined} from '@ant-design/icons'
import './styles/LeadListOfASession.css';
import { Avatar, Button, Divider, Empty, Modal, notification, Popover, Skeleton,DatePicker } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import InterestedCandidateCard from './components/InterestedCandidateCard';
import ProcessedCandidateCard from './components/ProcessedCandidateCard';
import StatisticCard from './components/StatisticCard';
import DownloadIcon from '../../static/svg/DownloadIcon';
import FilterIcon from '../../static/svg/FilterIcon';
import FoldingHands from '../../static/images/folding-hands.gif';
import LeftArrow from '../../static/svg/LeftArrow';
import { downloadInterestedLeads, downloadInterestedLeadsByType, getSessionDetails, paginatedSessionDetails, requestToEndASession, statisticCardInformation } from '../../service/SamvadiniApiService';
import moment from 'moment';
import PopoverDropdown from './components/PopoverDropdown';
import SessionListSkeleton from './skeleton/SessionListSkeleton';
import HumanCrossIcon from '../../static/svg/HumanCrossIcon';
import DownArrow from '../../static/svg/DownArrow';
import UpArrow from '../../static/svg/UpArrow';
import HumanSearchIcon from '../../static/svg/HumanSearchIcon';
import HumanCrossNonCircleIcon from '../../static/svg/HumanCrossNonCircleIcon';
import PhoneTickIcon from '../../static/svg/PhoneTickIcon';
import PhoneCrossIcon from '../../static/svg/PhoneCrossIcon';
import InfoIcon from '../../static/svg/InfoIcon';
import ScrollToTopButton from './components/ScrollToTopButton';
import animationData from '../../static/lottie/samvadini-loader.json'
import Lottie from 'react-lottie';
import NewCustomPagination from './components/NewCustomPagination';
import StatisticCardSkeleton from './skeleton/StatisticCardSkeleton';
import SideSheetComponent from '../common/SideSheet';
import CrossIcon from '../../static/svg/CrossIcon';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import CityIcon from '../../static/svg/CityIcon';
import StarIcon from '../../static/svg/StarIcon';
import { enableLast30DaysForSelection, getActiveClientsForFilter, getLocationForFilter } from '../../service/commonService';
import CustomSearchDropdown from './components/CustomSearchDropdown';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';

const { RangePicker } = DatePicker;

const CLOSE_SESSION_MODAL_CONTENT = {
    "DECISION_CONTENT": "DECISION_CONTENT",
    "LOADER": "LOADER",
    "SESSION_CLOSED": "SESSION_CLOSED",
    "SESSION_CANNOT_BE_CLOSED": "SESSION_CANNOT_BE_CLOSED" 
}

function LeadListOfASession() {
    const scrollContainerRef = useRef(null);

    const totalItemsPerPage = 8;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalItems, setTotalItems] = useState(0);

    const history = useHistory();
    const [activeSessionDetailsTab, setActiveSessionDetailsTab] = useState('currentSession'); // 'currentSession' | 'pastSession'
    const [activeCandidateDetailsTab, setActiveCandidateDetailsTab] = useState('interested'); // 'interested' | 'all'
    const [searchTerm, setSearchTerm] = useState('');

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const hideTabs = params.get('hideTabs');
    const sessionDetails = params.get('sessionDetails') && JSON.parse(params.get('sessionDetails'));
    const [completeResponsePayload, setCompleteResponsePayload] = useState({});

    const [visible, setVisible] = useState(false);
    const [closable, setClosable] = useState(false);
    const [closeSessionModalContent, setCloseSessionModalContent] = useState(CLOSE_SESSION_MODAL_CONTENT.DECISION_CONTENT);

    const [showIneligibleLeadsDetails, setShowIneligibleLeadsDetails] = useState(false);
    const dropdownRef = useRef(null);

    const [loading, setLoading] = useState(true);
    const [statisticCardLoading, setStatisticCardLoading] = useState(false);

    const [allCandidatesPaginationLoader, setAllCandidatesPaginationLoader] = useState(false);

    const [sessionSummaryData, setSessionSummaryData] = useState({});

    const [interestedLeads, setInterestedLeads] = useState([]);
    const [allLeads, setAllLeads] = useState([]);

    const [inEligibleLeadsInfo, setInEligibleLeadsInfo] = useState({});
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
    };

    const pollingIntervalRef = useRef(null);

    const [sessionInformation, setSessionInformation] = useState({});
    const [showFilter, setShowFilter] = useState(false);
    const [showDesktopFilter, setShowDesktopFilter] = useState(false);
    const [filterData, setFilterData] = useState([
        { id: 0, name: 'Client', isActive: false },
        { id: 1, name: 'City',isActive: false },
        { id: 2, name: 'Referral Date', isActive: false }
    ]);

    // All Filters related var
    const [filterList, setFilterList] = useState([
        {
            id: 1,
            header: 'City',
            icon: <CityIcon />,
            isExpanded: false
        },
        {
            id: 3,
            header: 'Clients',
            icon: <StarIcon />,
            isExpanded: false
        }
    ]);

    const [showSideSheet, setShowSideSheet] = useState(false);

    const [selectedCities, setSelectedCities] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [expandedFilterId, setExpandedFilterId] = useState(null);
    const [cityOptions, setCityOptions] = useState([]);
    const [citySearchText, setCitySearchText] = useState('');
    const cityDebouncedSearchText = useDebouncedValue(citySearchText);
    const [popularCities, setPopularCities] = useState([
        {
            id: 0,
            label: 'Delhi',
            isChecked: false
        },
        {
            id: 1,
            label: 'Mumbai',
            isChecked: false
        },
        {
            id: 2,
            label: 'Bangalore',
            isChecked: false
        },
        {
            id: 3,
            label: 'Pune',
            isChecked: false
        }
    ]);
    const [startDate, setStartDate] = useState();
	const [endDate, setEndDate] = useState();
    const dateFormat = 'YYYY/MM/DD';
    const [clientList, setClientList] = useState([])

    const onExpandFilter = (id) => {
        const modifiedFilter = [...filterList];

        setCityOptions([]);

        for (let item = 0; item < modifiedFilter.length; item++) {
            if(modifiedFilter[item].id === id) {
                if(id === expandedFilterId) {
                    modifiedFilter[item].isExpanded = false;
                    setExpandedFilterId(null);
                }
                else {
                    modifiedFilter[item].isExpanded = true;
                    setExpandedFilterId(id);
                }

            } else {
                modifiedFilter[item].isExpanded = false;
            }
        }

        setFilterList([...modifiedFilter]);
    }

    const onSearchCities = (label) => {

        if(!label) {
            setCityOptions([]);
            return
        }

        let data = {
            search: label
        }
        getLocationForFilter(data).then(response => {
            if(response?.data?.locationList.length) {
                const locationData = [];

                for(let i=0;i<response?.data?.locationList?.length; i++) {
                    locationData.push({label:response?.data?.locationList[i], value: response?.data?.locationList[i]});
                }

                setCityOptions([...locationData]);
            } else {
                setCityOptions([]);
            }
        }).catch(err=> {
            console.log(err);
            setCityOptions([]);
        })
    }

    useEffect(() => {
        onSearchCities(cityDebouncedSearchText)
    }, [cityDebouncedSearchText])


    const [statisticCardData, setStatisticCardData] = useState([]);

    const [sessionCloseErrorMessage,setSessionCloseErrorMessage] = useState('');

    const goBack = () => {
        if(hideTabs) {
            history.push('/samvadini-session-list');
        } else {
            history.push('/samvadini-v2');
        }
    }

    const setStatisticCardInformation = (response) => {
        const statistic = [
            {
                id: 0,
                label: 'Leads Uploaded',
                count: 0,
                imageUrl: '/image/add-properties.png'
            },
            {
                id: 1,
                label: 'Connected',
                count: 0,
                imageUrl: '/image/call-ai.png'
            },
            {
                id: 2,
                label: 'Interested',
                count: 0,
                imageUrl: '/image/done-ai.png'
            }
        ]

        if(response?.data?.summaryData?.summary?.leadsUploaded) {
            statistic[0].count = response?.data?.summaryData?.summary?.leadsUploaded;
        }

        if(response?.data?.summaryData?.summary?.totalCallConnected) {
            statistic[1].count = response?.data?.summaryData?.summary?.totalCallConnected;
            statistic[1].percentage = ((parseInt(response?.data?.summaryData?.summary?.totalCallConnected)/parseInt(response?.data?.summaryData?.summary?.leadsUploaded))*100).toFixed(2);
        }

        if(response?.data?.summaryData?.summary?.totalInterestedLeads) {
            statistic[2].count = response?.data?.summaryData?.summary?.totalInterestedLeads;
            statistic[2].percentage = ((parseInt(response?.data?.summaryData?.summary?.totalInterestedLeads)/parseInt(response?.data?.summaryData?.summary?.totalCallConnected))*100).toFixed(2);
        }

        setStatisticCardData([...statistic]);
    }

    const clearPollingInterval = () => {
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
          }
    }

    const checkForSelectedCitiesAndClients = (payload) => {
        if(selectedCities.length) {
            const cityList = [];

            for (let i = 0; i < selectedCities.length; i++) {
                cityList.push(selectedCities[i].label.toLowerCase());
            }

            payload.cities = [...cityList];
        } else {
            payload.cities = null;
        }

        if(selectedClients.length) {
            const clientList = [];

            for (let i = 0; i < selectedClients.length; i++) {
                clientList.push(selectedClients[i].label.toLowerCase());
            }

            payload.clients = [...clientList];
        } else {
            payload.clients = null;
        }
    }

    const getLeadListOfASession = async() => {
        if (!sessionInformation.intentCallingSessionId) return;
        
        setAllCandidatesPaginationLoader(true);
        try {

            const offset = (currentPage-1)*totalItemsPerPage;

            const payload = {
                intentCallingSessionId: sessionInformation.intentCallingSessionId,
                type: activeCandidateDetailsTab,
                limit: totalItemsPerPage,
                offset: offset
            }

            checkForSelectedCitiesAndClients(payload);

            const response = await paginatedSessionDetails(payload);

            if(activeCandidateDetailsTab === 'all') {
                if(response?.data?.allLeads?.length) {
                    setAllLeads([...response?.data?.allLeads]);
                } else {
                    setAllLeads([]);
                }

                if(response?.data?.totalLeads) {
                    setTotalItems(response?.data?.totalLeads);
                } else {
                    setTotalItems(0);
                }
                
                setInterestedLeads([]);
            } else {
                if(response?.data?.interestedLeads?.length) {
                    setInterestedLeads([...response?.data?.interestedLeads]);
                }else {
                    setInterestedLeads([]);
                }
                setAllLeads([]);
            }

            if(response?.data?.isSessionCompleted) {
                clearPollingInterval();
            }

            setCompleteResponsePayload(response?.data);

            setLoading(false);
            setAllCandidatesPaginationLoader(false);
        } catch(err) {
            setLoading(false);
            setAllCandidatesPaginationLoader(false);
            console.log(err);
        }
    }

    useEffect(() => {
        if(sessionDetails?.intentCallingSessionId) {
            setSessionInformation(sessionDetails);
        }
        getActiveClientList();
    }, [sessionDetails?.intentCallingSessionId]);

    const startPolling = () => {
        getLeadListOfASession();
        getSessionDetailSummary();
        pollingIntervalRef.current = setInterval(() => {
            getLeadListOfASession();
            getSessionDetailSummary();
        }, 30000);
    }

    const getSessionDetailSummary = () => {
        if(sessionInformation?.intentCallingSessionId) {
            setStatisticCardLoading(true);

            statisticCardInformation(sessionInformation?.intentCallingSessionId).
            then(response=> {
                setStatisticCardInformation(response);
                setSessionSummaryData(response?.data?.summaryData || {});
                setInEligibleLeadsInfo(response?.data?.summaryData?.summary?.rejectionReasons || {});

                if(response?.data?.summaryData?.summary?.isSessionCompleted) {
                    clearPollingInterval();
                }

                setStatisticCardLoading(false);
            }).catch(err=> {
                setStatisticCardLoading(false);
                console.log(err);
            })
        }
    }
    
    useEffect(() => {
        console.log(sessionInformation, 'session info');
      if(sessionInformation?.intentCallingSessionId) {
        startPolling();
      }

      return () => {
        if (pollingIntervalRef.current) {
            clearInterval(pollingIntervalRef.current);
            pollingIntervalRef.current = null;
        }
      }
    }, [sessionInformation?.intentCallingSessionId])

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowIneligibleLeadsDetails(false);
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const downloadExcel = () => {


        const payload = {
            intentCallingSessionId: sessionInformation.intentCallingSessionId,
            type: activeCandidateDetailsTab,
        }

        checkForSelectedCitiesAndClients(payload);

        downloadInterestedLeadsByType(payload)
        .then(res=> {
            if(res?.data?.downloadUrl) {
                window.open(res.data.downloadUrl, '_blank')
            } else {
                notification['error']({
                    message: 'File download response error, please try again later.'
                })
            }
        }).catch(e=> {
            notification['error']({
                message: 'File download error, please try again later.'
            })
        })
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    useEffect(() => {
        clearPollingInterval();

      if(activeCandidateDetailsTab != 'all') {
        startPolling();
      } else if(sessionInformation?.intentCallingSessionId) {
        getLeadListOfASession();
      }

    }, [activeCandidateDetailsTab, currentPage])
    

    // if(loading) {
    //     return (
    //         <SessionListSkeleton />
    //     )
    // }

    const handleDateRange = (dates) => {
        console.log(dates);
		if (dates === null) {
			setStartDate(null);
			setEndDate(null);
		}
		else {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	}

    const toggleIneligibleSection = () => {
        console.log(showIneligibleLeadsDetails);
        setShowIneligibleLeadsDetails(prevState=>!prevState);
    }

    const getActiveClientList = () => {
        getActiveClientsForFilter().then(data => {
			console.log(data.data.activeClients)

            if(data?.data?.activeClients?.length) {
                const activeClients = [...data.data.activeClients];

                for (let i = 0; i < activeClients.length; i++) {
                    activeClients[i].isExpanded = false;
                    activeClients[i].id = i;
                }

                setClientList([...activeClients]);
            }
		});
    }

    const applyFilters = () => {
        setShowFilter(false);
        setShowDesktopFilter(false);

        clearPollingInterval(); // doing this to stop interval with older scoped values

        if(activeCandidateDetailsTab != 'all') {
            startPolling();
        }

        if(currentPage != 1) {
            setCurrentPage(1); // this state change will inturn call session list API
        } else {
            if(sessionInformation?.intentCallingSessionId) {
                getLeadListOfASession();
            }
        }

        setFilterList([
            {
                id: 1,
                header: 'City',
                icon: <CityIcon />,
                isExpanded: false
            },
            {
                id: 3,
                header: 'Clients',
                icon: <StarIcon />,
                isExpanded: false
            }
        ]);
    }

    const getFilterInformation = () => {
        return (
            <>
                {/* <Divider style={{margin:'16px 0px'}}/> */}

                {
                    filterList.map((filterItem, idx)=>(
                        <Fragment key={idx}>
                            <div className="filter-item" onClick={()=>onExpandFilter(filterItem.id)}>
                                <div className='filter-details'>
                                    {/* {filterItem.icon} */}
                                    <span className='inter-medium-14-20 color-252526' style={{userSelect:'none'}}>{filterItem.header}</span>
                                </div>
                                {
                                    filterItem?.isExpanded ? <UpOutlined />: <DownOutlined />
                                }
                                
                            </div>

                            {
                                filterItem.id === 1 && filterItem.isExpanded ? (
                                    // <AdvanceFilterCitySelection onCitySelect={(data)=>setSelectedCities([...data])}/>
                                    <CustomSearchDropdown 
                                    placeholder={'e.g Delhi'}
                                    onSearch={(value)=>setCitySearchText(value)}
                                    dropdownOptions={cityOptions}
                                    onSelect={(data)=>setSelectedCities([...data])}
                                    value={selectedCities}
                                    checkboxGroupVisible={true}
                                    checkboxGroupItems={popularCities}
                                    />
                                ): null
                            }

                            {
                                filterItem.id === 3 && filterItem.isExpanded ? (
                                    <CustomSearchDropdown 
                                        showSearch={false}
                                        checkboxGroupVisible={true}
                                        checkboxGroupItems={clientList}
                                        onSelect={(data)=>setSelectedClients([...data])}
                                        value={selectedClients}
                                    />
                                ): null
                            }
                            <Divider style={{margin:'16px 0px'}}/>
                        </Fragment>
                    ))
                }

                {/* <div className="clear-filter-container" onClick={resetFilters}><span className='inter-regular-12-16 color-252526'>Remove all filters</span></div> */}
                <Button className='primary-btn-styles-v2 w-100' onClick={()=>applyFilters()}>
                    <span className='inter-regular-14-20 plain-white'>Apply</span>
                </Button>
            </>
        )
    }
    
    const closeSession = () => {
        requestToEndASession(sessionInformation?.intentCallingSessionId)
        .then(res=> {
            if(res?.data?.success) {
                setClosable(true);
                setCloseSessionModalContent(CLOSE_SESSION_MODAL_CONTENT.SESSION_CLOSED);
                trackEvent('session_successfully_closed', {sessionId: sessionInformation?.intentCallingSessionId}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            } else {
                setClosable(true);
                setSessionCloseErrorMessage(res?.data?.message);
                setCloseSessionModalContent(CLOSE_SESSION_MODAL_CONTENT.SESSION_CANNOT_BE_CLOSED);
                trackEvent('session_close_request_rejected', {sessionId: sessionInformation?.intentCallingSessionId}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
            }
        }).catch(err=> {
            setClosable(true);
            setCloseSessionModalContent(CLOSE_SESSION_MODAL_CONTENT.SESSION_CANNOT_BE_CLOSED);
        })
    }

    const initiateSessionCloseRequest = () => {
        trackEvent('session_close_request_initiated', {sessionId: sessionInformation?.intentCallingSessionId}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
        setCloseSessionModalContent(CLOSE_SESSION_MODAL_CONTENT.LOADER);
        setTimeout(() => {
            closeSession();
        }, 2000);
    }

    const refreshSessionDetails = () => {
        if(closeSessionModalContent === CLOSE_SESSION_MODAL_CONTENT.SESSION_CLOSED) {
            history.push('/samvadini-v2');
        }

        setVisible(false);
    }

  return (
    <>
    <div className='gray-main-container'  ref={scrollContainerRef}>
        {/* <Navbar searchTerm={searchTerm} setSearchTerm={setSearchTerm}/> */}

        {
            searchTerm ? null : (
                <>
                <div className="back-navigation d-flex align-items-center" onClick={goBack}>
                    <LeftArrow />
                    <span className='back-text'>Back</span>
                </div>

                <div className='d-flex justify-content-between align-items-center leads-dashboard-header'>
                    <span className='lead-list-hero-text d-flex no-additional-margin'>
                        Leads Dashboard
                        <img src={FoldingHands} style={{width: '34px', height:'34px', marginLeft: '12px', marginTop: '-12px'}} />
                    </span>

                    {
                        sessionSummaryData?.summary?.status === 'Completed' ? null: (
                            <div 
                            className={`close-session-btn`}
                            style={{gap: '16px'}}
                            onClick={()=>setVisible(true)}>
                                <CrossIcon strokeColor='#FFF'/> 
                                <span className='close-session-btn-text'>Close Session</span>
                            </div>
                        )
                    }
                    
                </div>

                {
                    hideTabs ? null: (
                        <div className="tab-and-btn-container d-flex justify-content-between">
                            <div className="session-tab-container">
                                <div 
                                className={`single-tab ${activeSessionDetailsTab === 'currentSession' ? 'active-tab':''}`}
                                onClick={()=>setActiveSessionDetailsTab('currentSession')}
                                > 
                                    {
                                        activeSessionDetailsTab === 'currentSession' ? <div className="active-dot"></div> : null
                                    }
                                    
                                    Current Session
                                </div>

                                <div 
                                className={`single-tab ${activeSessionDetailsTab != 'currentSession' ? 'active-tab':''}`}
                                onClick={()=>history.push('/samvadini-session-list')}
                                >
                                    Past Sessions
                                </div>
                            </div>

                            {/* <Button className='create-session-btn'><span className='create-session-btn-text'><PlusOutlined /> Create New Session</span></Button> */}
                        </div>
                    )
                }


                <div className="session-brief-description">
                    <div className="session-desc">
                        <div className="session-desc-individual-item">
                            <span className='session-desc-individual-item-header'>Date Created</span>
                            <span className='session-desc-individual-item-content'>
                                {sessionSummaryData?.summary?.createdAt ? moment(sessionSummaryData?.summary?.createdAt).format("DD/MM/YYYY"): null}
                            </span>
                        </div>
                        <div className="session-desc-individual-item">
                            <span className='session-desc-individual-item-header'>Time</span>
                            <span className='session-desc-individual-item-content'>
                                {sessionSummaryData?.summary?.createdAt ? moment(sessionSummaryData?.summary?.createdAt).format("hh:mm a"): null}
                            </span>
                        </div>
                        <div className="session-desc-individual-item">
                            <span className='session-desc-individual-item-header'>Status</span>
                            <span className='session-desc-individual-item-content'>{sessionSummaryData?.summary?.status === 'Completed' ? 'Done': 'Session in progress'}</span>
                        </div>
                    </div>
                    <div className="session-type-container">{sessionSummaryData?.summary?.type?.includes('Smart') ? 'Smart Logic': sessionSummaryData?.summary?.type}</div>
                </div>

                <Divider style={{width:'93%',margin: '16px auto', minWidth: '93%'}}/>

                {/* TILE CONTAINER */}
                <div className="card-tile-container margin-horizontal-32">
                    
                    {
                        statisticCardLoading ? <StatisticCardSkeleton />:
                        statisticCardData.map((item, idx) => {
                            return <StatisticCard key={idx} value={item}/>
                        })
                    }
                </div>

                <div className="ineligible-dropdown-container" onClick={toggleIneligibleSection} ref={dropdownRef}>
                    <div className='d-flex align-items-center' style={{gap:'6px'}}>
                        <HumanCrossIcon />
                        <span className='color-252526 inter-semibold-16-24'>{inEligibleLeadsInfo?.total || 0} Ineligible leads</span>
                    </div>

                    {
                        inEligibleLeadsInfo?.total ? (
                            <div className='d-flex align-items-center' style={{gap:'6px', cursor:'pointer'}}>
                                <span className='single-option-text color-252526 inter-medium-14-20'>View Details</span>
                                {
                                    showIneligibleLeadsDetails ? <UpArrow />: <DownArrow />
                                }
                            </div>
                        ): null
                    }
                    

                    {
                        showIneligibleLeadsDetails && inEligibleLeadsInfo?.total ? (
                            <div className='ineligible-leads-detail-section d-flex flex-column'>
                                {
                                    inEligibleLeadsInfo?.nonUnique ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <HumanSearchIcon />
                                                <span className='inter-medium-12-18'>Non unique leads</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.nonUnique} leads</span>
                                        </div>
                                    ): null
                                }

                                {
                                    inEligibleLeadsInfo?.notReferredByYou ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <HumanCrossNonCircleIcon />
                                                <span className='inter-medium-12-18'>Non referred by you</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.notReferredByYou} leads</span>
                                        </div>
                                    ): null
                                }
                                
                                {
                                    inEligibleLeadsInfo?.alreadyCalled ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <PhoneTickIcon />
                                                <span className='inter-medium-12-18'>Already called by you</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.alreadyCalled} leads</span>
                                        </div>
                                    ): null
                                }
                                
                                
                                {
                                    inEligibleLeadsInfo?.incorrectPhoneNumber ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <PhoneCrossIcon />
                                                <span className='inter-medium-12-18'>Incorrect numbers</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.incorrectPhoneNumber} leads</span>
                                        </div>
                                    ): null
                                }

                                {
                                    inEligibleLeadsInfo?.other ? (
                                        <div className='ineligible-leads-info'>
                                            <div className='d-flex align-items-center' style={{gap:'6px'}}>
                                                <InfoIcon height={14} width={14} strokeColor='#000'/>
                                                <span className='inter-medium-12-18'>Others</span>
                                            </div>
                                            <span className='inter-semibold-12-18'>{inEligibleLeadsInfo?.other} leads</span>
                                        </div>
                                    ): null
                                }
                                
                                
                            </div>
                        ): null
                    }

                    
                </div>

                <div className="filtered-candidate-card-container margin-horizontal-32">

                    {/* TABS SECTION */}
                    <div className="filtered-candidate-top-section d-flex justify-content-between align-items-center">
                        <div className="filtered-candidate-tab-container d-flex">
                            <div 
                            className={`filtered-candidate-single-tab ${activeCandidateDetailsTab === 'interested' ? 'filtered-candidate-active-tab': null}`}
                            onClick={()=>setActiveCandidateDetailsTab('interested')}
                            >
                                Interested
                            </div>
                            <div 
                            className={`filtered-candidate-single-tab ${activeCandidateDetailsTab != 'interested' ? 'filtered-candidate-active-tab': null}`}
                            onClick={()=>{
                                setActiveCandidateDetailsTab('all');
                                clearPollingInterval(); // we need polling only on the interested leads tab
                            }
                        }>
                                All
                            </div>
                        </div>

                        <div className="filtered-candidate-options d-flex">
                            <div className="single-option-container d-flex align-items-center" onClick={downloadExcel}>
                                <DownloadIcon />
                                <span className='single-option-text'>Download</span>
                            </div>
                            <div className='sessionlist-filter-popup'>
                                <Popover
                                content={getFilterInformation}
                                trigger={'click'}
                                visible={showDesktopFilter}
                                overlayStyle={{width: '300px'}}
                                placement='topLeft'
                                title={<b>Add filters</b>} 
                                >
                                    <div className="single-option-container d-flex align-items-center" onClick={()=>setShowDesktopFilter((prev)=> !prev)}>
                                        <FilterIcon />
                                        <span className='single-option-text'>Filter</span>
                                    </div>
                                </Popover>
                            </div>
                            <div 
                            onClick={()=>setShowFilter(true)}
                            className="single-option-container session-mobile-sidebar">
                                <FilterIcon />
                                <span className='single-option-text'>Filter</span>
                            </div>
                        </div>
                    </div>

                    <div className="session-list-pagination-container d-flex justify-content-end mt-16">
                        {
                            totalItems && activeCandidateDetailsTab === 'all' ? (
                                <NewCustomPagination
                                    totalItems={totalItems}
                                    totalItemsPerPage={totalItemsPerPage}
                                    currentPage={currentPage}
                                    onPageChange={handlePageChange}
                                />
                            ): null
                        }
                    
                    </div>

                    {
                        activeCandidateDetailsTab === 'interested' ? (
                            <div className="filtered-candidate-cards d-flex align-items-center">
                                
                                {
                                    allCandidatesPaginationLoader ? (
                                    <>
                                        <Skeleton active />
                                    </>
                                    ):
                                    interestedLeads.length ? interestedLeads.map((item,idx) => {
                                        return <InterestedCandidateCard key={idx} indexVal={idx} candidate={item}/>
                                    }): (
                                        <div className='d-flex align-items-center justify-content-center w-100 flex-column'>
                                            {
                                                sessionSummaryData?.summary?.isSessionCompleted ? (<Empty description=""/>): <Lottie
                                                options={defaultOptions}
                                                height={300}
                                                width={300}
                                                speed={1}
                                                />
                                            }
                                            <span className='inter-medium-16-24'>
                                                    {
                                                        sessionSummaryData?.summary?.isSessionCompleted ? 'No Interested Candidates Found!': 'Calling In Progress. Candidates will appear in a few minutes '
                                                    }
                                            </span>
                                        </div>
                                    )
                                }
                            
                            </div>
                        ): (
                            <div className="filtered-candidate-cards d-flex">
                                {
                                    allCandidatesPaginationLoader ? (
                                        <>
                                            <Skeleton active />
                                        </>
                                        ):allLeads.length ? allLeads.map((item,idx) => {
                                        return <ProcessedCandidateCard key={idx} indexVal={idx} candidate={item}/>
                                    }): <div className='d-flex align-items-center justify-content-center w-100'><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={
                                        <span>
                                          Sorry! No result found
                                        </span>
                                      }/></div>
                                }
                            </div>
                        )
                    }

                </div>
                </>
            )
        }

        

    </div>
    <ScrollToTopButton scrollContainerRef={scrollContainerRef}/>
    {
        showFilter ? (
                <SideSheetComponent 
                sidername={'Filters'} 
                hideDismissIcon={false}
                hideBottomButtons={true}
                containerStyle={{width:"85%"}}
                showNavigator={false}
                parentContainerStyles={{background: 'rgba(0, 0, 0, 0.74)'}}
                onDismiss={() => { setShowFilter(false)}}>
                    <div className='mobile-adv-filter-sidesheet'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <span className='inter-bold-16-18 color-252526 text-align-start'>
                                Filters
                            </span>
                            <div onClick={()=>setShowFilter(false)}>
                                <CrossIcon />
                            </div>
                        </div>
                        <Divider style={{margin:'16px 0px'}}/>
                        {getFilterInformation()}
                    </div>
                </SideSheetComponent>
        ): null
    }
    <Modal
      visible={visible}
      onCancel={()=>setVisible(false)}
      footer={null}
      closable={false}
      className='close-session-modal'
      maskClosable = {false}
      bodyStyle={{padding: '0px', borderRadius: '12px'}}
      >
        {
            closable ? (
                <div style={{cursor: 'pointer', padding: '12px'}} className='d-flex justify-content-end' onClick={()=>refreshSessionDetails()}>
                    <CrossIcon />
                </div>
            ): null
        }
        {
            closeSessionModalContent === CLOSE_SESSION_MODAL_CONTENT.DECISION_CONTENT ? (
                <div>
                    <div className='session-close-bg'>
                        <img src='/image/Cross-mark.png' className='session-close-icon' />
                    </div>
                    <div>
                        <div className='close-session-text-content'>
                            <span className='inter-semibold-24-28 close-session-content-header'>Are you sure you want to close the session?</span>
                            <span 
                             style={{color: '#747474'}}
                            className='inter-regular-14-20 close-session-content-sub-header'>You will have to start a new session</span>
                        </div>

                        <div className='close-session-cta-section'>
                            <Button onClick={()=> initiateSessionCloseRequest()} className='session-stop-primary primary-btn-styles-v2 inter-semibold-16-24 plain-white'>Yes</Button>
                            <Button onClick={()=> setVisible(false)} className='session-stop-secondary btn-light-blue inter-semibold-16-24 color-1D5FFF'>No</Button>
                        </div>
                    </div>
                </div>
            ): null
        }

        {
            closeSessionModalContent === CLOSE_SESSION_MODAL_CONTENT.LOADER ? (
                <>
                    <div>
                        <img src='/image/dafrm.png' style={{width: '200px', height: '176px'}} />
                    </div>
                    <div className='close-session-text-content' style={{paddingBottom: '24px'}}>
                        <span className='inter-semibold-24-28 close-session-content-header'>Analyzing...</span>
                        <span className='inter-regular-14-20 close-session-content-sub-header' style={{color: '#747474'}}>Your session is being analysed.</span>
                    </div>
                </>
            ): null
        }

        {
            closeSessionModalContent === CLOSE_SESSION_MODAL_CONTENT.SESSION_CLOSED ? (
                <>
                    <div>
                        <img src='/image/abstract.png' style={{padding: '24px', width: '100%',objectFit:'contain'}} />
                    </div>
                    <div className='close-session-text-content' style={{paddingBottom: '24px'}}>
                        <span className='inter-semibold-24-28 close-session-content-header'>Your session is closed</span>
                        <span className='inter-regular-14-20 close-session-content-sub-header' style={{color: '#747474'}}>Start new one again.</span>
                    </div>
                </>
            ): null
        }

        {
            closeSessionModalContent === CLOSE_SESSION_MODAL_CONTENT.SESSION_CANNOT_BE_CLOSED ? (
                <>
                    <div>
                        <img src='/image/session-not-closed-banner.png' style={{padding: '24px', width: '100%',objectFit:'contain'}} />
                    </div>
                    <div className='close-session-text-content' style={{paddingBottom: '24px'}}>
                        <span className='inter-semibold-24-28 close-session-content-header'>Session can’t be closed</span>
                        <span className='inter-regular-14-20 close-session-content-sub-header' style={{color: '#747474'}}>{sessionCloseErrorMessage || 'Session is Running and more interested leads will be generated shortly.'}</span>
                    </div>
                </>
            ): null
        }
        
      </Modal>
    </>
    
  )
}

export default LeadListOfASession