import React, { useState, useEffect, useContext, useReducer } from 'react';
import Lead from '../common/candidateApplication/CandidateApplicationCard.component';
// import CandidateProfilePopupV2 from './candidateProfilePopupV2Components'
import { useHistory } from 'react-router-dom';
import Moment from 'react-moment';
import moment from "moment";
import useGAEventsTracker from '../../service/useGSEventsTracker';
import { trackEvent } from '../../service/engagementMonitoringService';
import CONSTANTS from '../../constants/constants';
import { DatePicker, Button, Tag, Space, Typography, Select, Skeleton, Pagination, Empty, Tabs, Modal, Result, Dropdown, Menu, notification, Breadcrumb } from 'antd';
import { AppContext } from '../../App';
import { getActiveClientsForFilter, disabledDate, getStatusListForFilters, fetchActivatedAndReferredLeads, getRapidoDocumentStatuses, getLocationForFilter, getMitraTeamMembersList } from './../../service/commonService'
import { SearchOutlined, LockOutlined, FireOutlined, FilterOutlined, WhatsAppOutlined, InfoCircleOutlined, DownOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { showHotLeadsPopUpBackend, getCandidatesData, downloadCandidatesPageData, displayexpiryDateChangedBanner, getUniquenessStatusForScheduledInterviewIds } from './../../service/leadService'
import SideSheet from "../common/SideSheet"
import SideInput from "../common/SiderInput"
import axios from 'axios';
import PageHeaderComponent from '../common/PageHeader';
import '../../css/lead.scss';
import { useLocation } from "react-router-dom";
import WhatsappTemplateModal from '../whatsappTemplateModal/WhatsappTemplateModal';
import { getViewDetailsData } from './../../service/leadService';
import CandidateProfilePopupV2 from './candidateProfilePopupV2Components/candidateProfilePopupV2';
import { useParams } from "react-router-dom";
import * as queryString from 'query-string';
import RaiseIssueSider from '../help/RaiseIssueSider.component';
import LocationFilterComponent from '../common/LocationFilter.component';
import VerifyEmailPopup from './../common/VerifyEmailPopup.component';
import { rollOutRecruitmentMilestoneVisibility, jobseekerApplicationInML } from '../../constants/utils';
import { getDisplayableClientName } from './../../utils/commonFunction';
import HotLeadsModal from '../leads/HotLeadsModal';
import NewLoader from '../common/NewLoader/NewLoader';
import axiosInstance from '../../service/interceptorService';
import ReassignLeadsDialog from './ReassignLeads/ReassignLeadsDialog';


const { Text } = Typography;
const { RangePicker } = DatePicker;
const louTours = {
	'Candidate page tracking': 815030298538
}
const LeadsPage = () => {
	const location = useLocation();
	const [pathNameCandidates, setPathNameCandidates] = useState(window.location.href.indexOf("candidates") > -1)
	const stateData = JSON.parse(location.state ? location.state : '{}');
	const history = useHistory();
	const [_, forceUpdate] = useReducer((x) => x + 1, 0);
	// const GAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const AllCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	// const CriticalCandidatesGAEventsTracker = useGAEventsTracker("Action buttons clicked")
	const { mitraReducer, setAlert, setSpin } = useContext(AppContext);
	const [leads, setLeads] = useState([]);
	const [startDate, setStartDate] = useState(moment().subtract(1, 'days').format('YYYY-MM-DD'));
	const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
	const [showDetail, setShowDetail] = useState(false);
	const [selectedLead, setSelectedLead] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [mitra, setMitra] = useState();
	const [filterSider, setfilterSider] = useState(false);
	const [clickRangePicker, setClickRangePicker] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false)
	const [breadcrumb, setBreadcrumb] = useState([{ link: '/recruiter-candidates', label: 'Candidates' }, { link: '/recruiter-candidates', label: window.location.href.indexOf("candidate") > -1 ? 'All Candidates' : 'Leads to Follow Up With' }])
	const [clientSelected, setClientSelcted] = useState([])
	const [referAgain, setReferAgain] = useState(false);
	const [currentJob, setCurrentJob] = useState({});
	const [submitdisable, setSubmitdisable] = useState(true);
	const [alternateNumber, setAlternateNumber] = useState();
	const [statusesSelected, setStatusesSelected] = useState([]);
	const [tagsSelected, setTagsSelected] = useState([...CONSTANTS.RECRUITER_DASHBOARD_CALL_STATUS_TAGS]);
	const dateFormat = 'YYYY/MM/DD';
	const dateFormatList = ['DD-MM-YYYY', 'DD/MM/YY', 'DD/MM/YYYY', 'DD-MM-YY'];
	const [filterApplied, setFilterApplied] = useState(true)
	const [totalApplicationCount, setTotalApplicationCount] = useState(0)
	const [currentPage, setCurrentPage] = useState(1)
	const [pageLimit, setPageLimit] = useState(50)
	const [downloadLoading, setDownloadLoading] = useState(false)
	const [pageTypeforLeadReassignment, setPageTypeforLeadReassignment] = useState('all')
	const [currentTab, setCurrentab] = useState('all-candidates')
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [candidatePostReferralSteps, setCandidatePostReferralSteps] = useState([]);
	const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
	const [leadDetails, setLeadDetails] = useState([]);
	const [selectedScheduledInterviewId, setSelectedScheduledInterviewId] = useState([]);
	const [selectedRecommendationHistoryId, setSelectedRecommendationHistoryId] = useState([]);
	const trackMoengageEvent = (event, obj) => {
		trackEvent(event, obj || {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
	}
	const [showBanner, setShowBanner] = useState(false)
	const [trackUpdatedExpiryDate, setTrackUpdatedExpiryDate] = useState(false)
	const { tab } = useParams();
	const [raiseIssueSider, setRaiseIssueSider] = useState(false);
	const [viewDetailLead, setViewDetailLead] = useState({});
	const [locationList, setLocationList] = useState([])
	const [showVerifyEmailPopup, setShowVerifyEmailPopup] = useState(false);
	const [changeEmail, setChangeEmail] = useState(false);
	const [mitraAppDownloadedFilter, setMitraAppDownloadedFilter] = useState([{ label: 'Downloaded', value: false, key: 'downloaded' }, { label: 'Not Downloaded', value: false, key: 'not_downloaded' }]);
	const [documentStatusFilter, setDocumentStatusFilter] = useState([{ label: 'Complete', value: false, key: 'complete' }, { label: 'Incomplete', value: false, key: 'incomplete' }]);
	const [applicationType, setApplicationType] = useState([{ label: 'Vahan App Applications', value: false, key: 'ma_applications' }, { label: 'Vahan Leader Applications', value: false, key: 'ml_applications' }]);
	const [realtimeUniquenessStatusData, setRealtimeUniquenessStatusData] = useState({});
	const [siToCheckUniquenessStatus, setSiToCheckUniquenessStatus] = useState([]);
	const [isUniquenessPending, setIsUniquenessPending] = useState(true);
	const [isUniqueLead, setIsUniqueLead] = useState(true);
	let realtimeUniquenessInterval;
	const [showLoaderForUniqueness, setShowLoaderForUniqueness] = useState(true);
	const [showHotLeadsModal, setShowHotLeadsModal] = useState(false);
	const [mitraEligibleForHotLeads, setMitraEligibleForHotLeads] = useState(false);
	const [lockHotLeads, setLockHotLeads] = useState(false);
	const [showCompleteApplicationForUniqueSi, setShowCompleteApplicationForUniqueSi] = useState(false);
	const [cancelToken, setCancelToken] = useState(null); // State to store the cancel token
	const [disableDatesBefore, setDisableDatesBefore] = useState(moment());

	const [hasDownloadAccess, setHasDownloadAccess] = useState(false);
	const [hasReassignAccess, setHasReassignAccess] = useState(false);
	const [mitraList, setMitraList] = useState([])
	const [showLeadReassignDialog, setShowLeadReassignDialog] = useState(false);
	const [allFiltersList, setAllFiltersList] = useState({});
	const key = `open${moment()}`;
	const placement = 'topRight';


	useEffect(() => {
		if (mitraReducer && mitraReducer.allowedFeatures) {
			setHasDownloadAccess(mitraReducer?.allowedFeatures?.[CONSTANTS.DOWNLOAD_DATA_ACCESS]?.isEnabledOverall)
			setHasReassignAccess(mitraReducer?.allowedFeatures?.[CONSTANTS.CAN_REASSIGN_LEADS]?.isEnabledOverall)
		}
	}, [mitraReducer])

	const handleScroll = () => {
		let leadsWrapperDiv = document.getElementsByClassName('leads-wrapper')[0];
		let backToTopBtn = document.getElementById("back-to-top");
		let y = leadsWrapperDiv.scrollTop;
		if (y >= 1500) {
			backToTopBtn.className = "cta showCta"
		} else {
			backToTopBtn.className = "cta hideCta"
		}
	};
	useEffect(() => {
		const data = {
			mitraId: mitraReducer?.mitraInfo?.id
		}
		showHotLeadsPopUpBackend(data).then(result => {
			if (result && result.data && result.data.response) {
				if (result?.data?.response?.show && result?.data?.response?.eligibility) {
					setShowHotLeadsModal(result.data.response.show);
				}
				if (result.data.response.eligibility) {
					setMitraEligibleForHotLeads(result.data.response.eligibility);
					setLockHotLeads(result.data.response.locked);
				}
			}
		})
		let zomatoRequiredMilestoneKeys = ["p3AppDownloadedButNoSectionFilled", "p2Only1or2SectionsFilled", "p1Upto3or4SectionsFilled", "p0Upto5SectionsFilled", "obFeesPaid"]
		let zomatoRequiredMilestoneKeysForActionNeeded = [
			"p3AppDownloadedButNoSectionFilled",
			"p2Only1or2SectionsFilled",
			"p1Upto3or4SectionsFilled",
			"p0Upto5SectionsFilled",
			"obFeesPaid",
			"200th_order_date",
			"completed1stTrip",
			"completed5thTrip",
			"completed10thTrip",
			"completed15thTrip",
			"completed20thTrip",
			"completed25thTrip",
			"completed30thTrip",
			"completed35thTrip",
			"completed40thTrip",
			"completed45thTrip",
			"completed50thTrip",
			"completed60thTrip",
			"completed75thTrip",
			"completed80thTrip",
			"completed100thTrip",
			"completed200thTrip"
		]
		if (mitraReducer?.mitraInfo) {
			setIsAdmin(mitraReducer?.mitraInfo?.role === 'admin' ? true : false)
			setMitra(mitraReducer?.mitraInfo ? mitraReducer?.mitraInfo : {})

			if (jobseekerApplicationInML(mitraReducer?.mitraInfo)) {
				setApplicationType([{ label: 'Vahan App Applications', value: true, key: 'ma_applications' }, { label: 'Vahan Leader Applications', value: false, key: 'ml_applications' }, { label: 'Claimed Applications', value: false, key: 'claimed_applications' }])
			}

			getActiveClientsForFilter().then(data => {
				setClientSelcted(data.data.activeClients)
			})
			getStatusListForFilters().then(data => {
				let filteredStatuses;
				console.log(tab);
				if (tab === 'action-needed-candidates' || tab === 'action-needed') {
					console.log('here');
					filteredStatuses = data.data.statuses.filter(status => {
						console.log(status.key);
						return !zomatoRequiredMilestoneKeysForActionNeeded.includes(status.key);
					});
				} else {
					filteredStatuses = data.data.statuses.filter(status => {
						return !zomatoRequiredMilestoneKeys.includes(status.key);
					});
				}
				setStatusesSelected(filteredStatuses);
				console.log(filteredStatuses);
			})
			getLocationForFilter().then(response => {
				setLocationList(response?.data?.locationList)
			})
			getMitraTeamMembersList().then(response => {
				setMitraList(response?.data?.data);
			});
			fetchActivatedAndReferredLeads().then(res => {
				// adding high intent events

			}).catch(err => console.log(err));
			if (tab === 'action-needed') {
				setCurrentab('action-needed')
				setBreadcrumb([{ link: '/', label: 'Home' }, { link: '/leads/action-needed-candidates', label: 'Action Required' }])
			} else if (tab === 'action-needed-candidates') {
				setCurrentab('action-needed-candidates')
				setBreadcrumb([{ link: '/recruiter-candidates', label: 'Candidates' }, { link: '/leads/action-needed-candidates', label: 'Action Required' }])
			} else if (tab === 'all') {
				setCurrentab('all')
				setBreadcrumb([{ link: '/', label: 'Home' }, { link: '/leads/all-candidates', label: 'All Candidates' }])
			} else if (tab === 'all-candidates') {
				setCurrentab('all-candidates')
				setBreadcrumb([{ link: '/recruiter-candidates', label: 'Candidates' }, { link: '/leads/all-candidates', label: 'All Candidates' }])
			}
			setPathNameCandidates(window.location.href.indexOf("candidates") > -1);
		}
		setPageLimit(50)
	}, [mitraReducer])

	useEffect(() => {
		if (mitraReducer && mitraReducer.allowedFeatures) {
			let viewAccessRestricted = mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.isEnable
			if (viewAccessRestricted) {
				let temp_var = moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days')
				console.log("DD", temp_var.format("YYYY-MM-DD"));
				setDisableDatesBefore(moment().subtract(mitraReducer?.allowedFeatures[CONSTANTS.VIEW_DATA_ACCESS]?.numberOfDays, 'days'))
			}
		}
	}, [mitraReducer])

	useEffect(() => {
		if (location?.pathname?.includes("action-needed-candidates")) {
			setPageTypeforLeadReassignment("action_required")
		}
		else if (location?.pathname?.includes("all")) {
			setPageTypeforLeadReassignment("all")
		}
	}, [])

	const getRealtimeUniquenessStatus = async () => {

		/* 

		About:  This is a section which determines uniqueness of a candidate in real-time,
				The evaluation is done on the basis of either the latest referral was bulk or single candidate.
				If the Latest referral was single candidate then just take that one application into consideration for real-time checks.
				If latest referral was bulk referral then use siIds of candidates whose product source is bulkReferral until some other value or null is found.
				Max 5 calls to server.
				Handled different messaging acc to that.

		*/

		// if this function gets called, clear the previous existing timeout function and start newone
		let iterator = 0;
		if (realtimeUniquenessInterval) {
			clearInterval(realtimeUniquenessInterval);
		}

		setShowLoaderForUniqueness(true);

		const siIds = [];

		// handle latest referred or bulk referral using latestMilestone property
		for (let i = 0; i < leads.length; i++) {
			if (leads[0]?.productSource[0] === 'bulkReferral') {
				// for bulk referrals

				// from the list if any of item is not the bulk referral then break 
				// This means bulk referred candidates are over.
				if (!leads?.[i]?.productSource?.[0]) break;

				if (leads[i]?.latestMilestone[0] === 'Lead Referred') {
					if (leads?.[i]?.scheduledInterviewId?.length) {
						siIds.push(...leads[i].scheduledInterviewId);
					}
				}

			} else {

				// for single candidate referral, chosing only latest application
				// if(leads[0]?.latestMilestone[0] === 'Lead Referred') {
				// 	siIds.push(...leads[0].scheduledInterviewId);
				// } else {
				// 	iterator = 5;
				// }
				// break;

				if (leads?.[0]?.companies?.length > 0) {
					const zomatoIndex = leads?.[0].companies.findIndex(company => company.toLowerCase() === 'zomato');
					if (zomatoIndex >= 0) {
						siIds.push(...[leads[0].scheduledInterviewId?.[zomatoIndex]]);
						break;
					}
					else if (leads[0]?.latestMilestone[0] === 'Lead Referred') {
						siIds.push(...leads[0].scheduledInterviewId);
					}
				} else {
					iterator = 5
				}
			}
		}
		setSiToCheckUniquenessStatus([...siIds]);

		realtimeUniquenessInterval = setInterval(async () => {
			if (iterator >= 4) {
				clearInterval(realtimeUniquenessInterval);
				setShowLoaderForUniqueness(false);
			}

			if (siIds.length) {
				iterator = iterator + 1;
				const apiData = await getUniquenessStatusForScheduledInterviewIds({ scheduleInterviewIds: siIds });
				if (apiData?.data?.data) {
					setShowCompleteApplicationForUniqueSi(true);
					setRealtimeUniquenessStatusData(apiData?.data?.data);
					if (Object.keys(apiData?.data?.data).length == 0) {
						setIsUniquenessPending(true)
					}
					else {
						// if(apiData?.data?.data && apiData.data?.data[])
						setIsUniquenessPending(false)
					}
					clearInterval(realtimeUniquenessInterval);
					setShowLoaderForUniqueness(false);
				}
				else {
					setIsUniquenessPending(true)
				}
			} else {
				iterator = 5;
			}
		}, 2000);


	};

	useEffect(() => {
		// This has been written to get realtime leads uniqueness status.
		getRealtimeUniquenessStatus();

		return () => {
			if (realtimeUniquenessInterval) {
				clearInterval(realtimeUniquenessInterval);
			}
		}

	}, [leads])


	useEffect(() => {
		let element = document.getElementsByClassName('leads-wrapper')[0];
		element.addEventListener('scroll', handleScroll, { passive: true });
		if (mitra?.id && filterApplied) {
			setFilterApplied(false)
			getCandidatesList()
		}
	}, [mitra, filterApplied])

	useEffect(() => {
		const params = queryString.parse(window.location.search);
		if (params?.startDate && params?.endDate) {
			handleDateRange([moment(params.startDate), moment(params.endDate)]);
		}
	}, []);

	const getCandidatesList = () => {
		setLoaded(false)
		let clientList = [], statusList = [], selectLocationList = [], teamMemberList = [], callStatusTagList = [], selectedMitraAppDownloadedFilter, selectedDocumentStatusFilter, selectedApplicationType;
		clientSelected.map(client => {
			if (client.value) {
				clientList.push(client.label.toLowerCase())
			}
		})

		tagsSelected.map(client => {
			if (client.value) {
				if (client.key === 'connected' || client.key === 'notConnected') callStatusTagList.push(client.key)
				else callStatusTagList.push(client.label)
			}
		});

		mitraList.map(single_mitra => {
			if (single_mitra.value) {
				teamMemberList.push(single_mitra.id)
			}
		})

		statusesSelected.map(status => {
			if (status.value) {
				statusList.push(status.key)
			}
		})

		locationList.map(status => {
			if (status.value) {
				selectLocationList.push(status.label)
			}
		})

		selectedMitraAppDownloadedFilter = mitraAppDownloadedFilter.find(item => item.value)?.key;

		selectedDocumentStatusFilter = documentStatusFilter.find(item => item.value)?.key;

		selectedApplicationType = applicationType.find(item => item.value)?.key;

		if (selectLocationList.length > 0) {
			trackEvent('clicked_city_filter_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		}

		let data = {
			startDate: moment(startDate).format('YYYY-MM-DD'),
			endDate: moment(endDate).format('YYYY-MM-DD'),
			clients: JSON.stringify(clientList),
			statuses: JSON.stringify(statusList),
			callStatusTagList: JSON.stringify(callStatusTagList),
			teamMemberIds: JSON.stringify(teamMemberList),
			offset: (currentPage * pageLimit) - pageLimit,
			limit: pageLimit,
			incompleteApplications: currentTab === 'all-candidates' ? false : true,
			updatedExpiryDateLeads: trackUpdatedExpiryDate,
			locations: selectLocationList,
			mitraAppDownloadedFilter: selectedMitraAppDownloadedFilter,
			isEligibleForRecruitmentFlow: rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo),
			documentStatusFilter: selectedDocumentStatusFilter,
			applicationType: selectedApplicationType
		}

		createFiltersListForLeadReassignment(clientList, statusList, callStatusTagList, teamMemberList, selectLocationList)
		const t0 = performance.now();

		getCandidatesData(data).then((result) => {
			if (location.state) {
				openNextStepsPopUp()
			}
			setLoaded(true)
			setLeads(result.data.result);
			// if(result.data.count && Number(result.data.count) > 0){
			setTotalApplicationCount(Number(result.data.count))
			// }
			// setFilterApplied(false)
			const t1 = performance.now();
			console.log(`Api getCandidatesData call took ${t1 - t0} milliseconds.`);
			trackEvent('candidates_page_load_time', {
				candidates_page_load_time: (t1 - t0)
			}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		})
		if (currentTab === 'all-candidates' && !trackUpdatedExpiryDate) {
			displayexpiryDateChangedBanner({ startDate: startDate, endDate: endDate }).then(response => {
				setShowBanner(response.data.showBanner)
			})
		}
	}

	const createFiltersListForLeadReassignment = (clientList = [], statusList = [], callStatusTagList = [], teamMemberList = [], selectLocationList = []) => {
		let data = {
			startDate: moment(startDate).format("YYYY-MM-DD"),
			endDate: moment(endDate).format("YYYY-MM-DD"),
			clients: clientList,
			statuses: statusList,
			callStatusTagList: callStatusTagList,
			teamMemberIds: teamMemberList,
			locations: selectLocationList,
		}
		setAllFiltersList(data)
	}

	const openNextStepsPopUp = () => {
		setTimeout(() => {
			let serialNo = 0;
			const steps = CONSTANTS.CANDIDATE_POST_REFERRAL_STEPS.map(ele => {
				if (ele?.clients?.includes(stateData?.clientPreference?.toLowerCase())) {
					serialNo++;
					if (!ele.hasClickableUrl) {
						return <h5 style={{ textAlign: 'left' }} key={'point' + serialNo}> {serialNo} {ele.message}</h5>;
					} else {
						// eslint-disable-next-line
						var urlRegex = /https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)/g;
						let urls = ele.message.match(urlRegex)
						let msgBreakup = [];
						let msg = ele.message;
						urls.forEach((url) => {
							let index = msg.indexOf(url)
							msgBreakup.push(msg.slice(0, index));
							msgBreakup.push(msg.slice(index, index + url.length));
							msg = msg.slice(index + url.length, msg.length);
						})
						msgBreakup.push(msg);
						let main = <span>
							{
								msgBreakup.map((element, index) => {
									if (urls.includes(element)) {
										return <a key={index} href={element} target='_blank' rel="noreferrer">{element}</a>;
									} else {
										return element;
									}
								})
							}
						</span>
						return <h5 style={{ textAlign: 'left' }} key={'point' + serialNo}> {serialNo}. {main} </h5>;
					}
				}
			})
			setCandidatePostReferralSteps(steps)
			setIsModalOpen(true)
		}, 500);
	}
	const deleteChip = (name, item) => {
		if (name === 'status') {
			let newStatus = [...statusesSelected]
			newStatus.map((single_status) => {
				if (single_status.label === item.label) {
					single_status.value = false;
				}
			})
			setStatusesSelected(newStatus)
		}
		else if (name === 'team_member') {
			let newCategory = [...mitraList]
			newCategory.map((single_category, index) => {
				if (single_category.name === item.name) {
					single_category.value = false
				}
			})
			setMitraList(newCategory)
		}
		else if (name === 'category') {
			let newCategory = [...clientSelected]
			newCategory.map((single_category) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setClientSelcted(newCategory)
		}
		else if (name === 'updateExpiryFilter') {
			setTrackUpdatedExpiryDate(false)
		}
		else if (name === 'location') {
			let newCategory = [...locationList]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setLocationList(newCategory)
		}
		else if (name === 'call_status') {
			let newCategory = [...tagsSelected]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setTagsSelected(newCategory)
		}
		else if (name === 'mitraAppDownload') {
			let newCategory = [...mitraAppDownloadedFilter]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setMitraAppDownloadedFilter(newCategory)
		}
		else if (name === 'documentStatus') {
			let newCategory = [...documentStatusFilter]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setDocumentStatusFilter(newCategory)
		}
		else if (name === 'applicationType') {
			let newCategory = [...applicationType]
			newCategory.map((single_category, index) => {
				if (single_category.label === item.label) {
					single_category.value = false
				}
			})
			setApplicationType(newCategory)
		}
		setFilterApplied(true)
	}

	const handleDownload = () => {
		setShowVerifyEmailPopup(true)
	}

	const handleSheet = async (email) => {
		if (leads.length > 0) {
			setDownloadLoading(true)
			let clientList = [], statusList = [], selectedLocationList = [], selectedMitraAppDownloadedFilter, selectedDocumentStatusFilter, selectedApplicationType, teamMemberList = [];
			clientSelected.map(client => {
				if (client.value) {
					clientList.push(client.label.toLowerCase())
				}
			})

			statusesSelected.map(status => {
				if (status.value) {
					statusList.push(status.key)
				}
			})

			mitraList.map(single_mitra => {
				if (single_mitra.value) {
					teamMemberList.push(single_mitra.id)
				}
			})

			locationList.map(location => {
				if (location.value) {
					selectedLocationList.push(location.label)
				}
			})

			selectedMitraAppDownloadedFilter = mitraAppDownloadedFilter.find(item => item.value)?.key;

			selectedDocumentStatusFilter = documentStatusFilter.find(item => item.value)?.key;

			selectedApplicationType = applicationType.find(item => item.value)?.key;

			const data = {
				startDate: moment(startDate).format('YYYY-MM-DD'),
				endDate: moment(endDate).format('YYYY-MM-DD'),
				clients: JSON.stringify(clientList),
				statuses: JSON.stringify(statusList),
				incompleteApplications: currentTab === 'all-candidates' ? false : true,
				updatedExpiryDateLeads: trackUpdatedExpiryDate,
				locations: selectedLocationList.length > 0 ? selectedLocationList : [],
				mitraAppDownloaded: selectedMitraAppDownloadedFilter,
				teamMemberIds: JSON.stringify(teamMemberList),
				documentStatus: selectedDocumentStatusFilter,
				applicationType: selectedApplicationType,
				email,
				changeEmail
			}
			downloadCandidatesPageData(data).then((response) => {
				if (response.data.responseCandidatesUrl) {
					window.open(response.data.responseCandidatesUrl, '_blank')
				}
				else {
					setAlert({
						display: true,
						text: response.data.message,
						type: response.data.message.toLowerCase().includes('no') ? 'error' : 'success'
					});
				}
				setDownloadLoading(false)
			})
		}
		else {
			setAlert({
				display: true,
				text: 'No data Present',
				type: 'error'
			});
		}
	}

	const whatsAppMessageModalCancel = () => {
		setWhatsAppMessageModal(false);
	};
	const onPhoneNumberChange = async (e) => {
		if (e.target.value.length > 10) {
			setAlert({
				display: true,
				text: 'Please enter only the 10 digit mobile no. without any prefix (+91 or 0)',
				type: 'error'
			});
			setSubmitdisable(true);
			return;
		}
		else {
			const alternatePhone = e.target.value;
			const regex = new RegExp("^[0-9]{10}$");
			if (regex.test(alternatePhone)) {
				setAlternateNumber(alternatePhone);
				setSubmitdisable(false);
			} else {
				setSubmitdisable(true);
			}

		}

	}
	const whatsappModalOpen = () => {
		const lead = leads.find(lead => lead.phoneNumber == stateData.phoneNumber)
		setSelectedLead(lead)
		if (lead?.recommendationHistoriesId || lead?.scheduledInterviewId) {
			let data = {
				recommendationHistoryIds: lead.recommendationHistoriesId,
				scheduledInterviewId: lead?.scheduledInterviewId,
			};
			getViewDetailsData(data).then((result) => {
				setLeadDetails(result.data);
				setWhatsAppMessageModal(true)

			});
		}
	}
	const scrollToTop = () => {
		trackEvent('Click_on_go_to_top_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		const leadsWrapper = document.getElementsByClassName("leads-wrapper");
		leadsWrapper[0].scrollTo({ top: 0, left: 0, behavior: 'smooth' });
	}
	const submitLeadFilter = () => {
		const data = new FormData();

		data.append('userId', selectedLead.id);
		data.append('oldPhoneNumber', currentJob.phoneNumber);
		data.append('userPhoneNumber', currentJob.phoneNumber);
		data.append('phoneNumber', alternateNumber);
		data.append('desiredRole', currentJob.jobType);
		data.append('jobId', currentJob.jobId);
		data.append('sourceName', 'mitra-leader');
		data.append('jobDemandId', currentJob.jobDemandId);
		data.append('sourceId', currentJob.mitraId);
		data.append('name', currentJob.name);
		data.append('clientPreference', currentJob.companyName);
		data.append('cityPreference', currentJob.companyCity);
		data.append('role', currentJob.role);
		data.append('companyCity', currentJob.companyCity);
		data.append('managerMitraID', mitra.managerMitraID ? mitra.managerMitraID : currentJob.mitraId);
		let url = process.env.REACT_APP_BACKEND_URL + '/referAgain';

		let response = axiosInstance.post(url, data, { withCredentials: true });
		setAlert({
			display: true,
			text: 'Trying to refer with other number....'
		});
		response.then(function (response) {
			if (!response.data.status) {
				let message = response.data.message ? response.data.message : 'Sorry, try another number.';
				setAlert({
					display: true,
					text: message,
					type: 'error'
				});
			} else {
				setAlert({
					display: true,
					text: 'successfully referred!!',
					type: 'success'
				});

				setReferAgain(false);
				setFilterApplied(true);
			}
		});
		setSubmitdisable(true);
	}
	const handlePicker = () => {
		setClickRangePicker(!clickRangePicker);
	}

	const handleDateRange = (dates) => {
		setClickRangePicker(!clickRangePicker);
		if (dates === null) {
			setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
			setEndDate(moment());
		}
		else {
			setStartDate(dates[0]);
			setEndDate(dates[1]);
		}
	}

	const clearAll = () => {
		clientSelected.map(item => {
			item.value = false;
		})
		statusesSelected.map(item => {
			item.value = false;
		})
		tagsSelected.map(item => {
			item.value = false;
		})
		locationList.map(item => {
			item.value = false;
		})
		mitraList.map(item => {
			item.value = false;
		})
		setStartDate(moment().subtract(7, 'days').format('YYYY-MM-DD'));
		setEndDate(moment());
		setFilterApplied(true)
	}



	const onClickTags = (label, index) => {
		if (label === 'Status') {
			statusesSelected[index].value = !statusesSelected[index].value;
		}
		if (label === 'Call Status') {
			tagsSelected[index].value = !tagsSelected[index].value;
		}
		if (label === 'Select Team Member') {
			mitraList[index].value = !mitraList[index].value;
		}
		if (label === 'Clients') {
			clientSelected[index].value = !clientSelected[index].value;
		}
		if (label === 'Location') {
			locationList[index].value = !locationList[index].value;
		}
		if (label === 'Mitra App (Mitra Clients)' || label === 'Mitra App') {
			mitraAppDownloadedFilter[index].value = !mitraAppDownloadedFilter[index].value;
			mitraAppDownloadedFilter[1 - index].value = false;
		}
		if (label === 'Document Status (Mitra Clients)') {
			documentStatusFilter[index].value = !documentStatusFilter[index].value;
			documentStatusFilter[1 - index].value = false;
		}
		if (label === 'Application Type') {
			applicationType.map(ele => ele.value = false);
			applicationType[index].value = !applicationType[index].value;
			// applicationType[1 - index].value = false;

		}
		forceUpdate();
	}

	const preventDefault = (e, label, item) => {
		e.preventDefault()
		deleteChip(label, item)
		console.log('Clicked! But prevent default.');
	}

	const applyFilterCondition = () => {
		let result = false;
		let arr = [statusesSelected, clientSelected, endDate];
		arr.map(item => {
			if (item) {
				for (let i = 0; i < item.length; i++) {
					if (item[i].value === true) {
						result = true;
						break;
					}
				}
			}
			if (moment(endDate).format("YYYY-MM-DD") !== moment().format("YYYY-MM-DD")) {
				result = true;
			}
		})
		return result;
	}
	const submitFilter = () => {
		setFilterApplied(true)
	}

	const fetchDisabledDates = (current) => {
		return ((current && current > moment().endOf('day')) || (current && current < disableDatesBefore.endOf("day")));
	}

	const commonTag = (label, arrObj) => {
		return <div className='tag-containers'>
			<div>{label}</div>
			{label === "Date Range" ?
				<Space>
					<RangePicker
						onClick={handlePicker}
						open={clickRangePicker}
						defaultValue={[moment(startDate, dateFormatList[0]), moment(endDate, dateFormatList[0])]}
						value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
						format={dateFormatList}
						onChange={handleDateRange}
						ranges={{
							Today: [moment().startOf('day'), moment().endOf('day')],
							'This Month': [moment().startOf('month'), moment().endOf('month')],
							'Last 7 days': [moment().startOf('day').subtract(6, 'days'), moment().endOf('day')],
							'Last 10 days': [moment().startOf('day').subtract(9, 'days'), moment().endOf('day')],
						}}
						disabledDate={fetchDisabledDates}
						allowClear={false}
						className="range-picker-leads"
					/>
				</Space> :
				label === "Location" ?
					<LocationFilterComponent
						locationList={locationList}
						onClickTags={onClickTags}
						label={label}
						setLocationList={setLocationList}
					/>
					:
					<div className='tag-wrapper-container'>
						{arrObj.map((item, index) => {
							return <Tag className={!item.value ? 'tags' : 'tags tag-active'}
								key={index}
								// closable={item.value}
								onClick={() => { onClickTags(label, index) }}
							>
								{label === "Clients" ? getDisplayableClientName(item.label) : (item.label || item.name)}
							</Tag>
						})}
					</div>
			}

		</div>
	}

	const getUpdatedExpiryApplications = () => {
		trackEvent('view_expiry_list_candidates_page', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		setShowBanner(false)
		setTrackUpdatedExpiryDate(true)
		setFilterApplied(true)
	}

	const getLatestRapidoDocumentStatuses = (item) => {
		const paramObject = {
			mitraId: mitra?.id,
			isSingleCandidate: false,
			company: item,
		};

		getRapidoDocumentStatuses(paramObject).then((res) => {
			const data = res.data;
			if (data) {
				if (data.success) {
					notification['success']({
						message: data.message ? data.message : 'Latest Document Status Refreshed Successfully.'
					});
				}
				else {
					notification['error']({
						message: data.message ? data.message : 'Error while Refreshing Latest Document Status.'
					});
				}
			}
			else
				notification['error']({
					message: 'Error while Refreshing Latest Document Status.'
				});
		}).catch((err) => {
			notification['error']({
				message: 'Error while Refreshing Latest Document Status.'
			});
		});
	};

	const onRefreshDocumentStatusButtonClick = (e) => {
		const { key } = e;

		switch (key) {
			case 'rapido':
				trackEvent(`refresh_doc_status_rapido`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				getLatestRapidoDocumentStatuses(key);
				break;
			case 'rapido_3pl':
				trackEvent(`refresh_doc_status_rapido_3pl`, {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
				getLatestRapidoDocumentStatuses(key);
				break;
			default:
				break;
		}
	};

	const refreshDocumentStatusMenu = (
		<Menu onClick={(e) => onRefreshDocumentStatusButtonClick(e)}>
			{CONSTANTS.REFRESH_DOCUMENT_STATUS_MENU.map((item, index) => {
				return <Menu.Item key={item.key}>
					{getDisplayableClientName(item.label)}
				</Menu.Item>
			})}
		</Menu>
	);

	const handlePageChangeSelection = (value) => {
		clearAll()

		trackEvent('candidates_page_tab_switch', { tabName: value }, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
		if (value === 'actionRequired') {
			setPageTypeforLeadReassignment("action_required")
			history.push({ pathname: '/leads/action-needed-candidates' })
			setCurrentab(pathNameCandidates ? 'action-needed-candidates' : 'action-needed')
			setTrackUpdatedExpiryDate(false)
			setShowBanner(false)
			setBreadcrumb(pathNameCandidates ? [{ link: '/recruiter-candidates', label: 'Candidates' }, { link: '/leads/action-needed-candidates', label: 'Action Required' }] : [{ link: '/', label: 'Home' }, { link: '/leads/action-needed-candidates', label: 'Action Required' }])
			setFilterApplied(true)
			trackMoengageEvent('action_needed_section_visited');
		} else if (value === 'all') {
			setPageTypeforLeadReassignment("all")
			history.push({ pathname: '/leads/all-candidates' })
			setCurrentab('all-candidates')
			setBreadcrumb([{ link: '/recruiter-candidates', label: 'Candidates' }, { link: '/leads/all-candidates', label: 'All Candidates' }])
			setFilterApplied(true)
		} else if (value === 'preReferralLeads') {
			history.push({ pathname: '/pre-referral-leads-candidates' })
		} else if (value === 'leadsToFollowUp') {
			history.push({ pathname: '/recruiter-candidates' })
		} else if (value === 'hotLeads') {
			if (lockHotLeads) {
				clickHotLeadsModal();
			} else {
				history.push({ pathname: '/hot-leads-candidates' })
			}
		}
	}

	const clickHotLeadsModal = () => {
		const data = {
			mitraId: mitraReducer?.mitraInfo?.id,
			clicked: true
		}
		showHotLeadsPopUpBackend(data).then(result => {
			if (result && result.data && result.data.response) {
				if (result?.data?.response?.show && result?.data?.response?.eligibility) {
					if (result?.data?.response?.show) {
						trackEvent('hot-leads-popup-shown', {}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
					}
					setShowHotLeadsModal(result.data.response.show);
				}
				if (result.data.response.eligibility) {
					setMitraEligibleForHotLeads(result.data.response.eligibility);
					setLockHotLeads(result.data.response.locked);
				}
			}
		})
	}

	const onFilterClick = () => {
		setfilterSider(true)
		setClickRangePicker(true)
	};

	const openFilterSlider = () => {
		setClickRangePicker(false);
		setfilterSider(true)
	};

	const onReassignLeadsClicked = () => {
		if (allFiltersList?.teamMemberIds?.length == 0) {
			notification.open({
				message: "Please select at least one team member from filters to proceed",
				key,
				duration: 5,
				placement,
				icon: <ExclamationCircleOutlined className='red-negative' />,
			});
		}
		else if (!loaded) {
			notification.open({
				message: "Please wait while candidates are loading.",
				key,
				duration: 5,
				placement,
				icon: <ExclamationCircleOutlined className='red-negative' />,
			});
		}
		else {
			trackEvent('opened_reassignment_dialog_from_listing', {
				page: pageTypeforLeadReassignment,
			}, CONSTANTS.ENGAGEMENT_TYPES.ALL_ENGAGEMENTS);
			setShowLeadReassignDialog(true)
		}
	}

	return (
		<>
			<HotLeadsModal
				showModal={showHotLeadsModal}
				setShowHotLeadsModal={setShowHotLeadsModal}
				mitraId={mitraReducer?.mitraInfo?.id}
			></HotLeadsModal>
			<div className="LeadPage mainContentZ">
				<div className="header">
					<PageHeaderComponent breadcrumb={breadcrumb} />
				</div>
				<div style={{ marginLeft: 'auto', display: 'flex', flexDirection: 'row' }}>

					<div className='page-filter-options show-only-desktop'>
						{mitraEligibleForHotLeads ?
							<Button onClick={(e) => handlePageChangeSelection('hotLeads')} style={{ display: 'flex', alignItems: 'center', borderRight: 'none', borderRadius: 0 }}>
								{lockHotLeads ? <LockOutlined /> : <FireOutlined />}Hot Leads
							</Button> : null}
						<Button onClick={(e) => handlePageChangeSelection('preReferralLeads')} style={{ borderRadius: 0 }}>
							Pre-Referral Leads
						</Button>
						<Button onClick={(e) => handlePageChangeSelection('leadsToFollowUp')} style={tab === 'action-needed' || tab === 'action-needed-candidates' ? { borderRadius: 0, borderRight: 'none' } : { borderRadius: 0 }}>
							Leads to Follow Up With
						</Button>
						<Button onClick={(e) => handlePageChangeSelection('all')} style={{ borderRadius: 0 }} type="primary" ghost>
							All
						</Button>

					</div>
					<div className="show-only-desktop" style={{ width: '40%', alignItems: 'flex-end', margin: '2%', display: 'flex', justifyContent: 'right' }}>
						<Button onClick={() => {
							history.push("/search-page")
						}} style={{ color: '#D34612', display: 'flex', alignItems: 'center' }} icon={<SearchOutlined />} danger ghost size={'large'} >Search</Button>
					</div>
					<Select className='show-only-mobile'
						style={{ margin: '2%', height: 'fit-content', width: '60%', textAlign: 'left', color: '#D34612' }}
						onChange={handlePageChangeSelection} defaultValue={'all'}>
						<Select.Option style={{ textAlign: 'left' }} value="leadsToFollowUp">Leads to Follow Up With</Select.Option>
						<Select.Option style={{ textAlign: 'left' }} value="preReferralLeads">Pre-Referral Leads</Select.Option>
						{/* <Select.Option style={tab === 'action-needed' ? { textAlign: 'left', color: '#D34612', backgroundColor: '#FFF' } : { textAlign: 'left' }} value="actionRequired">Action required</Select.Option> */}
						{mitraEligibleForHotLeads ?
							<Select.Option className="hot-leads-select" style={{ textAlign: 'left' }} value="hotLeads">{lockHotLeads ? <LockOutlined style={{ marginRight: '2%' }} /> : <FireOutlined style={{ marginRight: '2%' }} />}Hot Leads</Select.Option>
							: null}
						<Select.Option style={tab === 'all-candidates' ? { textAlign: 'left', color: '#D34612', backgroundColor: '#FFF' } : { textAlign: 'left' }} value="all">All</Select.Option>
					</Select>
					<div className='show-only-mobile-search'>
						<Button onClick={() => {
							history.push("/search-page")
						}} style={{ color: '#000000D9', borderColor: '#00000040' }} icon={<SearchOutlined />} danger ghost size={'medium'} ></Button>
					</div>
				</div>
				{/* {
					<div>
						<img onClick={()=>{
							// history.push('/refer-and-earn');
							// trackMoengageEvent('mitra_lead_program_banner_clicked')
							}} style={{cursor:"pointer", width: "80%", height: "250px"}} className='img-fluid' src='https://uploads-a.vahan.co/fileUploder/2024-01-10/OEShOy-web_photo_editor.jpg' />
					</div>
				} */}
				<div className="more-filters">
					<Space style={{ marginTop: '1%' }}>
						<RangePicker
							open={false}
							defaultValue={[moment(startDate, dateFormatList[0]), moment(endDate, dateFormatList[0])]}
							value={[moment(startDate, dateFormat), moment(endDate, dateFormat)]}
							format={dateFormatList}
							onClick={onFilterClick}
							allowClear={false}
							className="range-picker-leads"
							disabledDate={fetchDisabledDates}
						/>
					</Space>
					<div className="filter-set-container">
						<Button style={(applyFilterCondition()) ? { display: 'flex', alignItems: 'center', color: '#D34612', backgroundColor: '#FFEAE0', border: '1px solid #D34612' } : { display: 'flex', alignItems: 'center', color: '#D34612', border: '1px solid #D34612' }} onClick={openFilterSlider} icon={<FilterOutlined />}>Filter</Button>
						<div className="desktop-pagination" style={{}}>
							{
								totalApplicationCount > 0 ?
									<Pagination
										total={totalApplicationCount > 0 && totalApplicationCount}
										showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
										defaultPageSize={pageLimit}
										defaultCurrent={currentPage}
										responsive={true}
										showLessItems={true}
										onChange={(page) => { setCurrentPage(page); setFilterApplied(true) }}
										// hideOnSinglePage={true}
										showSizeChanger={false}
									/>
									: null
							}
						</div>
					</div>
					<div className="Chips-container">
						{
							jobseekerApplicationInML(mitraReducer?.mitraInfo) && applicationType?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'applicationType', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							mitraList?.map((single_mitra, index) => {
								if (single_mitra.value) {
									return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'team_member', single_mitra)}>{single_mitra.name} </Tag>
								}
							})
						}
						{
							trackUpdatedExpiryDate ?
								<Tag className="single-chip" closable onClose={(e) => preventDefault(e, 'updateExpiryFilter', false)}> Expiry Date Changed</Tag>
								: null
						}
						{
							clientSelected?.map((single_category, index) => {
								if (single_category.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'category', single_category)}>{getDisplayableClientName(single_category.label)} </Tag>
								}
							})
						}

						{
							statusesSelected?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'status', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							tagsSelected?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'call_status', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							[...new Set(locationList)]?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={index} className="single-chip" closable onClose={(e) => preventDefault(e, 'location', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							mitraAppDownloadedFilter?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'mitraAppDownload', single_status)}>{single_status.label} </Tag>
								}
							})
						}
						{
							documentStatusFilter?.map((single_status, index) => {
								if (single_status.value) {
									return <Tag key={'tagLead' + index} className="single-chip" closable onClose={(e) => preventDefault(e, 'documentStatus', single_status)}>{single_status.label} </Tag>
								}
							})
						}
					</div>
				</div>

				{
					showBanner ?
						<div style={{ margin: '20px' }}>
							<div className="warning-message-at-risk-container">
								<div className="warning-message-atrisk" >
									<InfoCircleOutlined style={{ color: '#D34612', margin: '13px 10px' }} />
									<div className='flex-desktop-row-mobile-column'>
										<Text style={{ margin: '10px 0px' }}>Expiry dates for some of your candidates have changed due to updated time-gating conditions.</Text>
										<Text className='view-list-updated-expiry' onClick={() => getUpdatedExpiryApplications()}>
											<Button type="primary" style={{ margin: '5px 0px' }}>View list</Button>
										</Text>
									</div>

									{/* <CloseOutlined onClick={() => {setShowBanner(false)}} style={{margin: '5px 10px 0px auto'}} /> */}
								</div>
							</div>
						</div>
						: null
				}

				<Modal style={{ padding: '40px' }} key="informationModal" visible={isModalOpen} open={isModalOpen} onCancel={() => { setIsModalOpen(false); location.state = null; }} footer={[]}>
					<Result
						status="success"
						extra={[
							<h6 key="leadStatus" style={{ color: 'green' }}> Your lead has been referred.</h6>,
							<h3 style={{ textAlign: 'left' }} key="infoModalTextHeader">Complete the	 steps below to get your lead activated.</h3>,

							...candidatePostReferralSteps,
							<br key="break" />,
							<Button style={{
								background: '#53c31b',
								color: '#ffff',
								borderRadius: '5px'
							}} onClick={() => { whatsappModalOpen(); }} key="whtsappMessageButton">Message On <WhatsAppOutlined style={{ fontSize: '20px' }} /></Button>,


						]}
					/>
				</Modal>

				{
					showLeadReassignDialog &&
					<Modal
						key="leadReassignDialog"
						title={null}
						centered
						visible={showLeadReassignDialog}
						open={showLeadReassignDialog}
						footer={null}
						width={600}
						height={700}
						className='reassign-leads-dialog'
						onCancel={() => { setShowLeadReassignDialog(false) }}
					>
						<ReassignLeadsDialog closeModal={() => { setShowLeadReassignDialog(false) }} showHeader={true} totalFilteredCandidates={totalApplicationCount} allFiltersFromParent={allFiltersList} allTeamMembersList={mitraList} isBulkReassignment={true} pageType={pageTypeforLeadReassignment} isLeadReassigned={getCandidatesList} />
					</Modal>
				}

				{whatsAppMessageModal ?
					<WhatsappTemplateModal
						lead={selectedLead}
						leadDetails={leadDetails}
						whatsAppMessageModal={whatsAppMessageModal}
						whatsAppMessageModalCancel={whatsAppMessageModalCancel}
					></WhatsappTemplateModal>
					: null}

				{/* <Tabs defaultActiveKey={tab === 'action-needed' ? '2' : '1'} centered className='candidates-tab-container' onChange={onChangeTabs}>
					<Tabs.TabPane tab="All Candidates" key="1">
					</Tabs.TabPane>
					<Tabs.TabPane tab="Action Needed" key="2">
					</Tabs.TabPane>
				</Tabs> */}
				{/* <div className="tabs-container"> */}

				{/* <button id="allCandidates"
						className= {!atRiskTab ? "tab active-tab" : "tab"}
						onClick={(e) => {
							setAtRiskTab(false);
							setbreadcrumb([{link: '/leads', label: 'Candidates'},{link: '/leads', label: 'All Candidates'}])
							GAEventsTracker("OpenAllCandidatesTab")
							setStartDate(moment().subtract(30, 'days').format('YYYY-MM-DD')); setEndDate(moment());
							setFilterApplied(true)
						}}>
						All Candidates
					</button>
					<button id="atRiskApplication"
						className= {atRiskTab ? "tab active-tab" : "tab"}
						onClick={(e) => {
							setAtRiskTab(true);
							setbreadcrumb([{link: '/leads', label: 'Candidates'},{link: '/leads', label: 'Critical Candidates'}])
							GAEventsTracker("OpenAtRiskTab")
							setFilterApplied(true)
							setStartDate(moment().subtract(13, 'd')); setEndDate(moment().subtract(7, 'd'));
						}}>
							<InfoCircleOutlined  style={{color: '#D34612', verticalAlign: 'baseline'}} />
						<span> Critical Candidates</span>
					</button> */}
				{/* </div> */}
				<div style={{ width: '100%', textAlign: 'end' }}>
					<Dropdown overlay={refreshDocumentStatusMenu}>
						<Button style={{ margin: '5px 20px 5px auto' }}>
							Refresh Document Status <DownOutlined />
						</Button>
					</Dropdown>

					{
						hasReassignAccess && totalApplicationCount > 0 && pageTypeforLeadReassignment == "all" ?
							// disabled={allFiltersList?.teamMemberIds?.length == 0}
							<Button style={{ margin: '5px 20px 5px auto' }} onClick={onReassignLeadsClicked} className={`${allFiltersList?.teamMemberIds?.length == 0 ? "opacity04" : ""}`}>
								Re-assign
							</Button>
							:
							null
					}

					{hasDownloadAccess ?
						<Button danger loading={downloadLoading} style={{ margin: '5px 20px 5px auto' }} onClick={() => { handleDownload(); AllCandidatesGAEventsTracker("DownloadAllCandidatesData"); }}>
							Download File
						</Button>
						: null}
				</div>

				<div className="leads-wrapper">

					{loaded ? leads.length === 0 ? (
						<div style={{ margin: '20px auto', height: '68vh' }}>
							<Empty
								description={
									<span>
										<button onClick={() => history.push('/add-single-candidate')} style={{ textDecoration: 'underline', color: 'red', fontWeight: '600' }}>Refer Candidates</button> to see data here.
									</span>
								} />
						</div>
					) : (
						leads.map((lead, index) => (
							<Lead
								key={index}
								lead={lead}
								leadDetails={leadDetails}
								setShowDetail={setShowDetail}
								setSelectedLead={setSelectedLead}
								setReferAgain={setReferAgain}
								setCurrentJob={setCurrentJob}
								setSpin={setSpin}
								mitra={mitra}
								setSelectedScheduledInterviewId={setSelectedScheduledInterviewId}
								setSelectedRecommendationHistoryId={setSelectedRecommendationHistoryId}
								isUniquenessPending={isUniquenessPending}
								realtimeUniquenessStatusData={realtimeUniquenessStatusData}
								siToCheckUniquenessStatus={siToCheckUniquenessStatus}
								showLoaderForUniqueness={showLoaderForUniqueness}
								showCompleteApplicationForUniqueSi={showCompleteApplicationForUniqueSi}
							/>
						))
					) : (
						<div style={{ height: "65vh", width: "100%" }}>
							<NewLoader comingFromPage={'leadsPage'} />
						</div>
					)}
					<br />
					<div className="mobile-pagination" style={{}}>
						{
							totalApplicationCount > 0 ?
								<Pagination
									total={totalApplicationCount > 0 && totalApplicationCount}
									showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
									defaultPageSize={pageLimit}
									defaultCurrent={currentPage}
									responsive={true}
									showLessItems={true}
									onChange={(page) => { setCurrentPage(page); setFilterApplied(true) }}
									showSizeChanger={false}
								/>
								: null
						}
					</div>
					<br />
					<a id="back-to-top" className="cta hideCta" onClick={() => scrollToTop()}>↑</a>
					<br />
				</div>
				<div className="bottom-detail-wrapper">
					{
						showDetail && (
							<CandidateProfilePopupV2
								selectedScheduledInterviewId={selectedScheduledInterviewId}
								selectedRecommendationHistoryId={selectedRecommendationHistoryId}
								visible={showDetail}
								setShowDetail={setShowDetail}
								lead={selectedLead}
								setRaiseIssueSider={setRaiseIssueSider}
								setViewDetailLead={setViewDetailLead}
								reassignAccess={hasReassignAccess}
								leadReassignedViaPopup={getCandidatesList}
								page={pageTypeforLeadReassignment}
							>

							</CandidateProfilePopupV2>)
					}
				</div>
			</div>
			{referAgain &&
				<SideSheet submit={{ disabled: submitdisable, onClick: submitLeadFilter }} sidername='Add New Number' onDismiss={() => setReferAgain(false)}>
					<div className="siderInnerContainer1">
						<div className="outerContainer">
							<SideInput onChange={onPhoneNumberChange} placeholder={'Example : 8884228005'}
								imgsrc={'phone.svg'} labelname={"* Add Alternate Number"} />
							<SideInput readOnly={true} value={selectedLead.phoneNumber}
								imgsrc={'phone.svg'} labelname={"Contact Number"} />
						</div>
					</div>
				</SideSheet>
			}
			{filterSider &&
				<SideSheet submit={{
					disabled: false
					, onClick: submitFilter
				}}
					clearAll={{ disabled: false, onClick: clearAll }}
					sidername={"Filter Candidates"}
					onDismiss={() => { setfilterSider(false) }}>
					<div className='filter-home-wrapper'>
						{jobseekerApplicationInML(mitraReducer?.mitraInfo) && commonTag("Application Type", applicationType)}
						{commonTag("Date Range", null)}
						{commonTag("Select Team Member", mitraList)}
						{commonTag("Status", statusesSelected)}
						{commonTag("Call Status", tagsSelected)}
						{commonTag("Clients", clientSelected)}
						{commonTag("Location", locationList)}
						{/* {commonTag(rollOutRecruitmentMilestoneVisibility(mitraReducer?.mitraInfo) ? "Vahan App" : "Vahan App (Vahan Clients)", mitraAppDownloadedFilter)}
						{commonTag("Document Status (Vahan Clients)", documentStatusFilter)} */}
					</div>
				</SideSheet>
			}
			{raiseIssueSider &&
				<RaiseIssueSider
					closeSider={() => setRaiseIssueSider(false)}
					mitraReducer={mitraReducer}
					selectedLead={viewDetailLead}
				/>
			}
			{
				showVerifyEmailPopup &&
				(
					<VerifyEmailPopup mitra={mitra} visible={showVerifyEmailPopup} closePopup={setShowVerifyEmailPopup} downloadFunction={handleSheet} changeEmail={changeEmail} setChangeEmail={setChangeEmail}></VerifyEmailPopup>
				)
			}
		</>

	);
};

export default LeadsPage;
